<template>
  <!--begin::Card-->
  <div class="mb-10">
    <h4 class="mb-10 font-weight-bold text-dark">
      Review your Details and Submit <br>
      <small>This section allows you to attach links to your social media pages to your website. Once these fields have been filled in the social media icons will appear in the navigation bar and footer of the website.</small>
    </h4>
    <form
      class="form"
      novalidate="novalidate"
      id="social_form"
      ref="form"
      v-on:submit="save"
    >
    <div class="form-group">
      <label>Facebook Url</label>
      <input
        type="url"
        class="form-control form-control-solid form-control-lg"
        name="facebook_url"
        placeholder="Facebook Url"
        v-model="form.facebook_url"
        />
    </div>
    <div class="form-group">
      <label>Twitter Url</label>
      <input
        type="url"
        class="form-control form-control-solid form-control-lg"
        name="twitter_url"
        placeholder="Twitter Url"
        v-model="form.twitter_url"
        />
    </div>
    <div class="form-group">
      <label>Google Url</label>
      <input
        type="url"
        class="form-control form-control-solid form-control-lg"
        name="google_url"
        placeholder="Google Url"
        v-model="form.google_url"
        />
    </div>
    <div class="form-group">
      <label>Instagram Url</label>
      <input
        type="url"
        class="form-control form-control-solid form-control-lg"
        name="instagram_url"
        placeholder="Instagram Url"
        v-model="form.instagram_url"
        />
    </div>
    <div class="form-group">
      <label>Youtube Url</label>
      <input
        type="url"
        class="form-control form-control-solid form-control-lg"
        name="youtube_url"
        placeholder="Youtube Url"
        v-model="form.youtube_url"
        />
    </div>
    <div class="form-group">
      <label>Tripadvisor Url</label>
      <input
        type="url"
        class="form-control form-control-solid form-control-lg"
        name="tripadvisor_url"
        placeholder="Tripadvisor Url"
        v-model="form.tripadvisor_url"
        />
    </div>
    <div class="form-group">
      <label>Linkedin Url</label>
      <input
        type="url"
        class="form-control form-control-solid form-control-lg"
        name="linkedin_url"
        placeholder="Linkedin Url"
        v-model="form.linkedin_url"
        />
    </div>
    <button
        :style="{'display': $route.name === 'wizard-social-link-details' ? 'block' : 'none'}"
        ref="form_submit"
        class="btn btn-primary mt-3 mr-4"
      >
        Submit
      </button>
      </form>

  </div>
  <!--end::Card-->
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Swal from "sweetalert2";

import KTUtil from "@/assets/js/components/util";
export default {
  name: "Social",
  data() {
    return {
      form :{
        facebook_url: '',
        twitter_url: '',
        google_url: '',
        instagram_url: '',
        youtube_url: '',
        tripadvisor_url: '',
        linkedin_url: ''
      },
    };
  },
  computed: {
    ...mapGetters([
      "currentUser"
    ]), 
  },
  mounted() {
    this.get();
    const form = KTUtil.getById("social_form");
    this.fv = formValidation(form, {
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });
    this.fv.on("core.form.valid", () => {
      this.$emit('validation', true);
    });

    this.fv.on("core.form.invalid", () => {
      this.$emit('validation', false);
    });
  },
  methods: {
    get() {
      ApiService.get('business/settings/wizard', 'social-link')
      .then(({ data }) => {
        this.form = data.data
      })
      .catch(({ response }) => {
        // context.commit(SET_ERROR, response.data.errors);
      });
    },
    save() {
      let currentUserId = this.currentUser.id;
      ApiService.post('business/settings/wizard/store/social-link/'+currentUserId, this.form)
      .then(({ data }) => {
          sessionStorage.removeItem("personalInfo");
        if  (this.$route.name === 'wizard-social-link-details') {
          Swal.fire("Success", "Social Links updated successfully", "success");
        }
        return true;
      })
      .catch(({ response }) => {
        return false;
      });
    },
    cancel() {
      //
    },
    onSubmit() {
      this.$refs.form_submit.click();
    }
  }
};
</script>
